import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export interface CircleProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Circle: React.FC<CircleProps> = React.forwardRef<
  HTMLInputElement,
  CircleProps
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={classNameBuilder(
        'flex aspect-square items-center justify-center rounded-full',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
})

Circle.displayName = 'Circle'

export default Circle
