import Column from 'components/atoms/column'
import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'
import Show from './show'

export interface InputWrapperProps {
  label?: string
  error?: string
  required?: boolean
  children?: React.ReactNode
  className?: string
  labelFor?: string
}
const InputWrapper: React.FC<InputWrapperProps> = props => {
  return (
    <Column className={classNameBuilder('space-y-xs', props.className)}>
      <Show when={!!props.label}>
        <label
          htmlFor={props.labelFor}
          className={classNameBuilder(
            'text-cool-gray-500 mb-1 text-left text-sm',
            'after:ml-0.5 after:text-red-300 after:content-["*"]',
            !props.required && 'after:hidden'
          )}
        >
          {props.label}
        </label>
      </Show>
      {props.children}
      <Text size="xs" color="red-400" className="h-0 text-left">
        {props.error}
      </Text>
    </Column>
  )
}

export default InputWrapper
