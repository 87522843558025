import { Dialog, Transition } from '@headlessui/react'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export interface ModalProps {
  isOpen?: boolean
  onClose?: () => void
  children?: React.ReactElement
  className?: string
  rootClassName?: string
}

export const Modal: React.FC<ModalProps> = props => {
  return (
    <Transition.Root show={Boolean(props.isOpen)} as={React.Fragment}>
      <Dialog
        as="div"
        className={classNameBuilder('relative z-50', props.rootClassName)}
        onClose={props.onClose}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-[#171633] bg-opacity-70 backdrop-blur-md transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={
                  props.className ??
                  'flex w-full transform shadow-xl transition-all sm:w-auto'
                }
              >
                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
