import Spinner from 'components/atoms/spinner'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'
import { Size } from 'types/size'

export type ButtonVariant = 'solid' | 'outline' | 'invisible'
export type ColorSchema = 'primary' | 'secondary' | 'accent' | 'none'
export type ButtonShape = 'rounded' | 'round'
export type ButtonType = 'button' | 'submit' | 'reset'

export interface ButtonProps {
  size?: Size
  colorSchema?: ColorSchema
  isDisabled?: boolean
  variant?: ButtonVariant
  type?: ButtonType
  shape?: ButtonShape
  onClick?: () => void
  pending?: boolean
  className?: string
}

export const Button: React.FC<ButtonProps> = props => {
  const {
    size = props.variant === 'invisible' ? null : 'md',
    variant = 'solid',
    type = 'button',
    colorSchema = 'primary',
    children,
    shape = 'rounded',
    isDisabled,
    onClick,
    pending,
    className,
  } = props

  return (
    <button
      className={classNameBuilder(
        'inline-flex items-center justify-center border font-medium uppercase',
        'focus:outline-none focus:ring-2 focus:ring-offset-2',
        'disabled:cursor-not-allowed disabled:bg-indigo-50 disabled:text-indigo-100 disabled:shadow-none',
        sizeClassMap[size],
        shapeClassMap[shape],
        variantClassMap[variant][colorSchema],
        className
      )}
      onClick={isDisabled || pending ? null : onClick}
      type={type}
      disabled={isDisabled}
    >
      {pending ? <Spinner size={size} /> : children}
    </button>
  )
}

export default Button

const sizeClassMap: Record<Size, string> = {
  xs: 'text-xs px-3 h-6',
  sm: 'px-5 h-8 text-xs',
  md: 'px-10 h-10 text-sm',
  lg: 'px-10 h-14 text-sm',
  xl: 'px-12 h-16 text-base',
}

const shapeClassMap: Record<ButtonShape, string> = {
  round: 'rounded-full',
  rounded: 'rounded-md',
}

const variantClassMap: Record<ButtonVariant, Record<ColorSchema, string>> = {
  solid: {
    primary: `text-white bg-indigo-400 hover:bg-indigo-200 active:bg-indigo-500 focus:ring-indigo-400 shadow-md border-transparent`,
    secondary: `text-white bg-gray-200 hover:bg-indigo-100 active:bg-indigo-300 ring-0 focus:outline-0 focus:ring-0 focus:ring-offset-0 focus:border-0 shadow-md border-transparent`,
    accent: `text-white bg-green-400 hover:bg-green-200 active:bg-green-500 focus:ring-green-400 shadow-md border-transparent`,
    none: 'focus:ring-0 focus:ring-offset-0',
  },
  outline: {
    primary: `text-indigo-400 border-indigo-400 bg-white hover:text-indigo-200 active:bg-indigo-50 active:text-indigo-400 active:border-transparent focus:ring-indigo-400`,
    secondary: `text-indigo-200 border-indigo-200 bg-white hover:text-indigo-200 active:bg-indigo-50 active:text-indigo-400 active:border-transparent focus:ring-indigo-400`,
    accent: `text-green-400 border-green-400 bg-white hover:text-green-200 active:bg-green-50 active:text-green-400 active:border-transparent focus:ring-green-400`,
    none: 'focus:ring-0 focus:ring-offset-0',
  },
  invisible: {
    primary: `text-indigo-400 border-0 bg-transparent active:text-indigo-400 focus:ring-indigo-400`,
    secondary: `text-indigo-200 border-0 bg-transparent active:text-indigo-400 focus:ring-indigo-400`,
    accent: `text-green-400 border-0 bg-transparent hover:text-green-200 active:text-green-400 focus:ring-green-400`,
    none: 'focus:ring-0 focus:ring-offset-0 bg-transparent border-0 w-auto p-0 m-0',
  },
}
